import styled from 'styled-components'

const Wrapper = styled.section`
  /* margin-top: calc(-1 * var(--nav-height)); */
  display: flex;
  align-items: center;
  background: url('/banner.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  img {
    width: 351px;
    height: 297px;
    margin-top: var(--nav-height);
    margin-right: 11%;
  }

  @media (max-width: 992px) {
    justify-content: center;
    img {
      margin-top: calc(100vh / 2 - 8rem);
      margin-right: 0;
      width: 278px;
      height: 235px;
    }
  }
`
export default Wrapper
