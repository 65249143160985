import styled from 'styled-components'

const Wrapper = styled.section`
  /* margin-top: calc(-1 * var(--nav-height)); */
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  width: 100%;
  padding: 106px 0 106px 106px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  .img {
    background-image: linear-gradient(
      to bottom,
      #e3bc8e,
      #e5c296,
      #e8c89e,
      #eacda7,
      #edd3af,
      #edd3af,
      #ecd2af,
      #ecd2af,
      #e8cca6,
      #e4c69e,
      #e0c195,
      #dcbb8d
    );
    width: 213px;
    height: 221px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 138px;
      height: 117px;
    }
  }
  .content {
    max-width: 461px;
    padding: 22px 51px;
    h1 {
      margin-bottom: 31px;
    }
  }
  @media (max-width: 992px) {
    padding: 106px 0;
    .img {
      display: none;
    }
  }
`
export default Wrapper
