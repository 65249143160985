import {
  Navbar,
  Banner,
  Intro,
  Mission,
  Vision,
  Service,
  Footer
} from './components/'

function App() {
  return (
    <div>
      <Navbar />
      <Banner />
      <Intro />
      <Mission />
      <Vision />
      <Service />
      <Footer />
    </div>
  )
}

export default App
