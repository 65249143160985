import styled from 'styled-components'

const Wrapper = styled.section`
  /* margin-top: calc(-1 * var(--nav-height)); */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  justify-content: center;
  background: url('/banner-mission.png');
  background-size: cover;
  background-position: center;
  .content {
    background: var(--grey-50);
    padding: 53px 130px 53px 35px;
    max-width: 747px;
    margin-right: -105px;
    p {
      color: var(--white);
    }
    h1 {
      color: var(--white);
      margin-bottom: 31px;
    }
  }
  img {
    width: 291px;
    height: 291px;
  }

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    background: url('/banner-mission-mobile.png');
    background-size: cover;
    background-position: center;
    .content {
      margin: 50px;
      padding: 35px;
    }
    img {
      width: 207px;
      height: 207px;
    }
  }
`
export default Wrapper
