import Wrapper from '../wrappers/MissionWrapper'
import { useAppContext } from '../context/appContext'

const Mission = () => {
  const { language, texts } = useAppContext()
  const text = texts[language].mission

  return (
    <Wrapper>
      <div className='content'>
        <h1>{text.title}</h1>
        <p>{text.item}</p>
      </div>
      <div className='img'>
        <img src={'/mission.png'} alt='mission' layout='fill' />
      </div>
    </Wrapper>
  )
}

export default Mission
