import React, { useReducer, useContext } from 'react'
import { LANGUAGE_CHANGE } from './actions'
import reducer from './reducer.js'

const language = localStorage.getItem('lang')

const langTexts = {
  mn: {
    menu: {
      about: 'Бидний тухай',
      service: 'Үйлчилгээ',
      contact: 'Холбоо барих'
    },
    intro: {
      title: 'Компанийн товч танилцуулга',
      item1:
        'Жемма шайн ХХК нь Үнэт металл, үнэт чулууны худалдаа үйлчилгээ эрхлэх зорилготойгоор 2019 оны 02 дугаар сарын 19-ний өдөр үүсгэн байгуулагдсан.',
      item2:
        'Бид үйл ажиллагаагаа Санхүүгийн Зохицуулах Хорооны ТЗ701/39 тоот Үнэт металл, үнэт чулууны эсхүл тэдгээрээр хийсэн эдлэлийн арилжаа эрхлэх Тусгай зөвшөөрлийн гэрчилгээний дагуу эрхэлж байна.'
    },
    mission: {
      title: 'Эрхэм зорилго',
      item: 'Дэлхийд алдартай хосгүй үнэ цэнэ бүхий ховор нандин, загварлаг үнэт эдлэлүүдийг үйлчлүүлэгчдэдээ санал болгож, үнэт эдлэлийн соёлыг түгээх болно.'
    },
    vision: {
      title: 'Бидний Алсын хараа',
      item1:
        'Бид дэлхийд алдартай үнэт эдлэлийн брэндүүдийг эх орондоо түгээн дэлгэрүүлж  алмаз, ховор эрдэнийн чулуу, үнэт эдлэлийг байгаль орчинд ээлтэй уул уурхай эрхэлдэг, гарал үүсэл нь тогтоогдсон газраас нийлүүлэх ханган нийлүүлэгчийн сүлжээг бий болгоно.',
      item2:
        'Олон улсын үнэт эдлэлийн салбарын жишигт нийцсэн дээд зэрэглэлийн компани болно.'
    },
    service: {
      title: 'Бүтээгдэхүүн үйлчилгээ',
      item1:
        'Үнэт металл, үнэт чулуу нь жил ирэх тусам үнэ цэнтэй болсоор байгаа нь гоо үзэсгэлэнгээс гадна практик ач холбогдолтой байдаг тул энэ бизнес нь цаг хугацааны хувьд ашигтай хэвээр байх болно.',
      item2:
        'Монголд үнэт эдлэлийн худалдаа эрхэлдэг олон компани, дистирбютер байгаа хэдий ч бид үнэт эдлэлийг түүхий эд хэлбэрээр импортлон урчууд, дархчид болон бусад Монгол брэндүүдэд худалдаалахын зэрэгцээ урлагийн гоёмсог үнэт эдлэлийг худалдан авагчдад өгөх чадвараараа бусдаас ялгарна. Энэхүү чадвар нь бизнесийн эерэг шийдвэр гаргахад түлхэц өгөх болно.'
    },
    footer: {
      address: 'УБ, Чингэлтэй дүүрэг, 4-р хороо, Сүхбаатарын гудамж, 8 тоот'
    }
  },
  en: {
    menu: {
      about: 'About US',
      service: 'SERVICE',
      contact: 'CONTACT US'
    },
    intro: {
      title: 'introduction of the company',
      item1:
        'Gemma Shine LLC was established on February 19, 2019, with the aim of trading in precious metals, jewelleries and gemstones.',
      item2:
        "We conduct our operations are in compliance with the Financial Regulation Commission's Consent of Special License for Trading in Precious Metals, Gemstones, or Jewellries of These, No. T3701/39"
    },
    mission: {
      title: 'OUR MISSION',
      item: 'We will offer our customers rare, precious and stylish jewelry of unparalleled value, which is famous in the world, and introduce the culture of jewelry.'
    },
    vision: {
      title: 'OUR VISION',
      item1:
        'We build a supplier network that provides local distribution for world-renowned jewelry brands, which is certified, eco-friendly mining of rare gems and jewelry.',
      item2:
        'We will become a leading high-class company in the international jewelry industry'
    },
    service: {
      title: 'OUR SERVICE',
      item1:
        'This business will continue to be profitable over time, as precious metals and precious stones continue to increase in value over the years, not only for their beauty but also for their practicality.',
      item2:
        'While there are many jewelry trading companies and distributors in Mongolia, we stand out by our ability to import jewelry as raw materials and sell them to artisans, blacksmiths, and other Mongolian brands while providing our customers with fine art jewelry. This ability will encourage positive business decisions.'
    },
    footer: {
      address: '8, Sukhbaatar street, 4th khoroo, Chingeltei district, Ub city.'
    }
  }
}

const initialState = {
  language: language ? language : 'mn',
  texts: langTexts
}

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const changeLanguage = () => {
    dispatch({ type: LANGUAGE_CHANGE })
  }

  return (
    <AppContext.Provider
      value={{
        ...state,
        changeLanguage
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = () => {
  return useContext(AppContext)
}

export { AppProvider, initialState, langTexts, useAppContext }
