import styled from 'styled-components'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 66px 0 0 0;
  justify-content: center;
  background: var(--white);
  h1 {
    margin-bottom: 56px;
  }
  .item {
    width: 100%;
    display: flex;
    img {
      width: 50%;
      height: 506px;
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      p {
        max-width: 484px;
        padding: 22px 22px;
      }
    }
  }

  @media (max-width: 992px) {
    padding: 66px 0;
    .item {
      flex-direction: column;
      &.reverse {
        flex-direction: column-reverse;
      }
      img {
        width: 100%;
        height: 270px;
      }
      .content {
        width: 100%;
      }
    }
  }
`
export default Wrapper
