import { LANGUAGE_CHANGE } from './actions'

const reducer = (state, action) => {
  if (action.type === LANGUAGE_CHANGE) {
    const lang = state.language === 'mn' ? 'en' : 'mn'
    localStorage.setItem('lang', lang)
    return {
      ...state,
      language: lang
    }
  }

  throw new Error(`no such action : ${action.type}`)
}

export default reducer
