import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--grey-50);
  padding: 44px 62px;
  .mobile-cpr {
    margin-top: 20px;
    color: var(--white);
    text-align: center;
    font-size: 12px;
  }
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .logo {
      width: 152px;
      height: 129px;
    }
    .contact {
      .item {
        display: flex;
        align-items: center;
        width: 659px;
        justify-content: space-between;
        .text {
          display: flex;
          align-items: center;
          color: var(--white);
          .icon {
            font-size: 22px;
            margin-right: 10px;
          }
          p {
            text-decoration: none !important;
            color: var(--white);
          }
        }
      }
    }
  }

  .container-mobile {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .logo {
      width: 152px;
      height: 129px;
    }
    .contact {
      width: 50%;
      .text {
        display: flex;
        align-items: center;
        color: var(--white);
        .icon {
          font-size: 22px;
          margin-right: 10px;
        }
        p {
          width: 85%;
          text-decoration: none !important;
          color: var(--white);
        }
      }
    }
  }

  @media (max-width: 992px) {
    padding: 20px 20px;
    .container {
      display: none;
      .contact {
        .item {
          width: auto;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .container-mobile {
      display: none;
    }
    .mobile-cpr {
      display: none;
    }
  }
`
export default Wrapper
