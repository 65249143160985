import Wrapper from '../wrappers/NavbarWrapper'
import { Logo } from '../components'
import { IoMenuOutline } from 'react-icons/io5'
import { VscChromeClose } from 'react-icons/vsc'
import { useState } from 'react'
import { useAppContext } from '../context/appContext'

const Navbar = () => {
  const [open, setOpen] = useState(false)
  const { language, changeLanguage, texts } = useAppContext()
  const text = texts[language].menu

  return (
    <Wrapper>
      <a href='/'>
        <Logo />
      </a>
      <div className='menus'>
        <ul className={!open ? 'hide' : ''}>
          <li>
            <a href='#intro'>{text.about}</a>
          </li>
          <li>
            <a href='#service'>{text.service}</a>
          </li>
          <li>
            <a href='#contact'>{text.contact}</a>
          </li>
        </ul>
        <div className='lang' onClick={changeLanguage}>
          {language === 'mn' ? 'eng' : 'mn'}
        </div>
        <div className='toggle' onClick={() => setOpen(!open)}>
          {open ? <VscChromeClose /> : <IoMenuOutline />}
        </div>
      </div>
    </Wrapper>
  )
}

export default Navbar
