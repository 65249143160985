import Wrapper from '../wrappers/FooterWrapper'
import Logo from './Logo'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdLocationOn, MdEmail } from 'react-icons/md'
import { useAppContext } from '../context/appContext'

const Footer = () => {
  const { language, texts } = useAppContext()
  const text = texts[language].footer

  return (
    <Wrapper id='contact'>
      <div className='container'>
        <Logo />
        <div className='contact'>
          <div className='item'>
            <div className='text'>
              <FaPhoneAlt className='icon' />
              <p>+976 - 94113168</p>
            </div>
            <div className='text'>
              <MdLocationOn className='icon' />
              <p>{text.address}</p>
            </div>
          </div>
          <div className='item'>
            <div className='text'>
              <MdEmail className='icon' />
              <p>info@gemmashine.mn</p>
            </div>
            <div className='text'>
              <p>Created by Ving | Copyright © 2022 Gemma Shine LLC</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-mobile'>
        <Logo />
        <div className='contact'>
          <div className='text'>
            <FaPhoneAlt className='icon' />
            <p>+976 - 94113168</p>
          </div>
          <div className='text'>
            <MdLocationOn className='icon' />
            <p>{text.address}</p>
          </div>
          <div className='text'>
            <MdEmail className='icon' />
            <p>info@gemmashine.mn</p>
          </div>
        </div>
      </div>
      <p className='mobile-cpr'>
        Created by Ving
        <br /> Copyright © 2022 Gemma Shine LLC
      </p>
    </Wrapper>
  )
}

export default Footer
