import styled from 'styled-components'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 66px 10px;
  justify-content: center;
  background: url('/banner-vision.png') rgba(255, 255, 255, 0.9);
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  h1 {
    margin-bottom: 49px;
  }
  .item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    img {
      width: 23px;
      height: 17px;
      margin-top: 4px;
    }
    p {
      max-width: 655px;
      padding: 0 17px;
      margin: 0;
    }
  }

  @media (max-width: 992px) {
  }
`
export default Wrapper
