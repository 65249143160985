import Wrapper from '../wrappers/ServiceWrapper'
import { useAppContext } from '../context/appContext'

const Service = () => {
  const { language, texts } = useAppContext()
  const text = texts[language].service

  return (
    <Wrapper id='service'>
      <h1>{text.title}</h1>
      <div className='item'>
        <img src={'/service1.png'} alt='service 1' layout='fill' />
        <div className='content'>
          <p>{text.item1}</p>
        </div>
      </div>
      <div className='item reverse'>
        <div className='content'>
          <p>{text.item2}</p>
        </div>
        <img src={'/service2.png'} alt='service 1' layout='fill' />
      </div>
    </Wrapper>
  )
}

export default Service
