import Wrapper from '../wrappers/VisionWrapper'
import { useAppContext } from '../context/appContext'

const Vision = () => {
  const { language, texts } = useAppContext()
  const text = texts[language].vision

  return (
    <Wrapper>
      <h1>{text.title}</h1>
      <div>
        <div className='item'>
          <img src={'/diamond-dot.png'} alt='dot' />
          <p>{text.item1}</p>
        </div>
        <div className='item'>
          <img src={'/diamond-dot.png'} alt='dot' />
          <p>{text.item2}</p>
        </div>
      </div>
    </Wrapper>
  )
}

export default Vision
