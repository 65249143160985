import Wrapper from '../wrappers/IntroWrapper'
import Logo from './Logo'
import { useAppContext } from '../context/appContext'

const Intro = () => {
  const { language, texts } = useAppContext()
  const text = texts[language].intro

  return (
    <Wrapper id='intro'>
      <div className='img'>
        <Logo black={true} />
      </div>
      <div className='content'>
        <h1>{text.title}</h1>
        <p>{text.item1}</p>
        <p>{text.item2}</p>
      </div>
    </Wrapper>
  )
}

export default Intro
