import styled from 'styled-components'

const Wrapper = styled.nav`
  height: var(--nav-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.7);
  padding: 0 25px;
  z-index: 999;
  position: fixed;
  img {
    width: 77px;
    height: 77px;
  }
  .menus {
    display: flex;
    align-items: center;
    ul {
      text-decoration: none;
      list-style: none;
      li {
        display: inline-block;
        text-transform: uppercase;
        margin-right: 54px;
        cursor: pointer;
        font-size: var(--medium-textSize);
        font-weight: 400;
        a {
          text-decoration: none;
          color: var(--white);
        }
      }
    }
    .lang {
      text-transform: uppercase;
      color: var(--grey-50);
      margin-right: 25px;
      padding: 6px;
      background: var(--white);
      font-weight: 600;
      border-radius: 27px;
      cursor: pointer;
    }
    .toggle {
      font-size: 32px;
      color: var(--white);
      padding-top: 5px;
      cursor: pointer;
    }
  }

  @media (min-width: 992px) {
    .toggle {
      display: none;
    }
  }

  @media (max-width: 992px) {
    ul {
      position: absolute;
      top: var(--nav-height);
      right: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0)
      );
      margin: 0;
      padding-bottom: 80px;
      li {
        display: block !important;
        margin-bottom: 31px;
      }
      &.hide {
        display: none !important;
        transition: all 2s linear;
      }
    }
  }
`
export default Wrapper
