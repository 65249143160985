import Wrapper from '../wrappers/BannerWrapper'
import Logo from './Logo'

const Banner = () => {
  return (
    <Wrapper>
      <Logo black={true} />
    </Wrapper>
  )
}
export default Banner
